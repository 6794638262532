// internal
import App from '@/page/app.vue';
import router from '@/ts/router';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
const pinia = createPinia()

// store
import { Store } from '@/ts/store';
  
// api
import api from '@/ts/api';
import tools from '@/ts/tools.js';

// dict
import dictCountry from '@framework/dict/country.js';
import dictFlag from '@framework/dict/flag.js';
import dictLang from '@framework/dict/lang.js';
import dictLangFlag from '@framework/dict/langflag.js';
import dictLangSorted from '@framework/dict/langsorted.js';

// directives
import UploadDirective from "./directives/upload";

// header
import Footer from '@/page/footer.vue';
import Head from '@/page/head.vue';
import Login from '@/page/login.vue';
import Box from '@/ui/box.vue';
import Gap from '@/ui/gap.vue';
import Checkbox from '@/ui/kit/checkbox.vue';
import Input from '@/ui/kit/input.vue';
import Switch from '@/ui/kit/switch.vue';
import Text from '@/ui/kit/text.vue';
import Loader from '@/ui/loaders/circles.vue';
import BlockTitle from '@/ui/local/blockTitle.vue';
import Popup from '@/ui/popup.vue';

// core
import Column from '@framework/ui/core_column.vue';
import Scroll from '@framework/ui/core_scroll.vue';


// app
const app = createApp(App)

// pinia
app.use(pinia)

// store
app.config.globalProperties.db = Store()

// router
app.use(router);
router.isReady().then(() => {app.mount('#app')});



// api
app.config.globalProperties.api = api

// tools
app.config.globalProperties.tools = tools

// dict
app.config.globalProperties.dict = {
    lang: dictLang,
    country: dictCountry,
    langsort: dictLangSorted,
    flag: dictFlag,
    langflag: dictLangFlag,
}

app.directive('upload', UploadDirective)

// dev
app.config.globalProperties.dev = process.env.NODE_ENV == 'development'

// if (process.env.NODE_ENV != 'development'){
//     window.console.log = function(){ return}
//     window.console.trace = function(){ return}
//     window.console.warn = function(){ return}
//     window.console.group = function(){ return}
//     window.console.groupEnd = function(){ return}
// }

// helpers
app.config.globalProperties.go = function(name: string) {router.push({name:name})}
app.config.globalProperties.is = function(name: string) {return router.currentRoute.value.name == name}
app.config.globalProperties.iss = function(name: string) {return router.currentRoute.value.name?.toString().startsWith(name)}
app.config.globalProperties.openlink = function(link: string) {window.open(link, '_blank');}
app.config.globalProperties.golink = function(link: Location) {window.location = link}
app.config.globalProperties.clipboard = async function(text: string) { await navigator.clipboard.writeText(text)},

 
// framework
app.component('Box', Box);
app.component('Gap', Gap);
app.component('Switch', Switch);
app.component('Checkbox', Checkbox);
app.component('Head', Head);
app.component('Footer', Footer);
app.component('Text', Text);
app.component('Input', Input);
app.component('Error', Error);
app.component('Login', Login);
app.component('Loader', Loader);
app.component('BlockTitle', BlockTitle);
app.component('Popup', Popup);
app.component('Column', Column);
app.component('Scroll', Scroll);